import React from "react";
import { Vortex } from "react-loader-spinner";

const Loader = () => {
  return (
    <Vortex
      visible={true}
      height="100"
      width="100"
      ariaLabel="vortex-loading"
      wrapperStyle={{}}
      wrapperClass="vortex-wrapper"
      colors={[
        "#1A9987",
        "#6F6F6F",
        "rgba(0, 141, 121, 0.9)",
        "#F3C652",
        "rgba(0, 141, 121, 0.63)",
        "#CECECE",
      ]}
    />
  );
};

export default Loader;
