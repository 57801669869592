import Loader from "./components/Loader";
import ScrollToTop from "./components/ScrollToTop";
import Router from "./routes";
import { Suspense } from "react";

function App() {
  return (
    <Suspense
      fallback={
        <div className="h-[100vh] flex items-center justify-center">
          <Loader />
        </div>
      }
    >
      <ScrollToTop />
      <Router />
    </Suspense>
  );
}

export default App;
