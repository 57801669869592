import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allTowns: null,
  newTowns: null,
};

const Towns = createSlice({
  name: 'townSlice',
  initialState,
  reducers: {
    setAllTowns: (state, action) => {
      state.allTowns = action.payload;
    },
    setNewTowns: (state, action) => {
      state.newTowns = action.payload;
    },
  },
});

export const { setAllTowns, setNewTowns } = Towns.actions;

export default Towns.reducer;
