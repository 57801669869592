import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";

const Page404 = lazy(() => import("./pages/Page404"));
const Home = lazy(() => import("./pages/home"));
const Town = lazy(() => import("./pages/towns"));
const AboutUs = lazy(() => import("./pages/aboutUs"));
const Freelancers = lazy(() => import("./pages/freelancers"));
const ContactUsForm = lazy(() => import("./pages/support/ContactUsForm"));
const ForgetPassword = lazy(() => import("./pages/auth/ForgetPassword"));
const Register = lazy(() => import("./pages/auth/Register"));
const Login = lazy(() => import("./pages/auth/Login"));
const SingleFreelancer = lazy(() =>
  import("./pages/freelancers/singleFreelancer")
);
const Navbar = lazy(() => import("./components/navbar"));
const Footer = lazy(() => import("./components/footer"));
const SingleTown = lazy(() => import("./pages/towns/singletown"));
const Services = lazy(() => import('./pages/services') )

export default function Router({ isDarkMode, handleThemeChange }) {
  const routes = useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to="/home" />, index: true },
        {
          path: "home",
          element: (
            <>
              <Navbar />
              <Home />
              <Footer />
            </>
          ),
        },
        {
          path: "towns",
          element: (
            <>
              <Navbar />
              <Town />
              <Footer />
            </>
          ),
        },
        {
          path: "about-us",
          element: (
            <>
              <Navbar />
              <AboutUs />
              <Footer />
            </>
          ),
        },
        {
          path: "freelancers",
          element: (
            <>
              <Navbar />
              <Freelancers />
              <Footer />
            </>
          ),
        },
        {
          path: "services",
          element: (
            <>
              <Navbar />
              <Services />
              <Footer />
            </>
          ),
        },
        {
          path: "contact-us",
          element: (
            <>
              <Navbar />
              <ContactUsForm />
              <Footer />
            </>
          ),
        },
        {
          path: "auth/login",
          element: <Login />,
        },
        {
          path: "auth/signup",
          element: <Register />,
        },
        {
          path: "auth/forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "freelancer/:id",
          element: (
            <>
              <Navbar />
              <SingleFreelancer />
              <Footer />
            </>
          ),
        },
        {
          path: "towns/:id",
          element: (
            <>
              <Navbar />
              <SingleTown />
              <Footer />
            </>
          ),
        },
        { path: "404", element: <Page404 /> },

        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ]);

  return routes;
}
