import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  freelancer: null,
  freelancerById: null,
  topFreelancers: null,
};

const Freelancer = createSlice({
  name: 'freelancerSlice',
  initialState,
  reducers: {
    setFreelancer: (state, action) => {
      state.freelancer = action.payload;
    },
    setFreelancerById: (state, action) => {
      state.freelancerById = action.payload;
    },
    setTopFreelancers: (state, action) => {
      state.topFreelancers = action.payload;
    },
  },
});

export const { setFreelancer, setFreelancerById, setTopFreelancers } =
  Freelancer.actions;

export default Freelancer.reducer;
