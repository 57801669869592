import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import User from './slices/User';
import Freelancer from './slices/Freelancer';
import Towns from './slices/Towns';
import Home from './slices/Home';

const store = configureStore({
  reducer: combineReducers({
    User,
    Freelancer,
    Towns,
    Home
  }),
});

export const { dispatch, getState } = store;

export default store;
