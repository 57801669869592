import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  topFreelancers: null,
  newTowns: null,
  townById: null,
};

const Home = createSlice({
  name: 'homeTopFreelancersSlice',
  initialState,
  reducers: {
    setTopFreelancers: (state, action) => {
      state.topFreelancers = action.payload;
    },
    setNewTowns: (state, action) => {
      state.newTowns = action.payload;
    },
    setTownById: (state, action) => {
      state.townById = action.payload;
    },
  },
});

export const { setTopFreelancers, setNewTowns, setTownById } = Home.actions;

export default Home.reducer;
